import React, { useCallback, useMemo } from "react";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import Typography from "@mui/material/Typography";
import {
    GridColDef,
    GridRowsProp,
    GridValueFormatterParams,
} from "@mui/x-data-grid-premium";

import { useAsyncThunkAction } from "../../../hooks/useAsyncThunkAction";
import { fetchCalendarDays } from "../../../store/calendarSlice";
import { Base } from "../../../framework/base";
import { useAppSelector } from "../../../framework/customStore";

import { VehicleListItemDto } from "../../../models/transport/vehicle";
import { ICostCenterItems } from "../../../models/costCenter/costCenterItems";

import { EmployeeListItemDto } from "../../../models/employee/employee";
import { Translations } from "../../../models/translations";
import { WorkShiftDto } from "../../../models/workShift/workShift";
import { WorkShiftTimeSlotItem } from "../../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useTranslation } from "react-i18next";
import { WorkTimeType } from "../../../models/workShitTimeSlot/workTimeType";
import { EmployeeDetailsTable } from "./employeeDetailsTable";
import { GroupedDataGrid } from "../../framework/groupedDataGrid";

export interface WorkSlotItem {
    employeeId: string;
    vehicleId: string;
    costCenterId: string;
    workTimeTypeId: string;
    amount: number;
    startTime: string;
    endTime: string;
    dateStr: string;
    comment: string;
}

export interface GridRow {
    id: string;
    hierarchy: string[];
    workSlotItems: WorkSlotItem[];
    vehicles: VehicleListItemDto[];
    costCenters: ICostCenterItems;
    workTimeTypes: WorkTimeType[];
    date: string;
    totals: Record<string, number>;
}

interface EmployeesVehicleGridProps {
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItems;
    workShifts: WorkShiftDto[];
    workTimeTypes: WorkTimeType[];
}

export const EmployeesVehicleGrid = ({
    vehicles,
    employees,
    costCenters,
    workShifts,
    workTimeTypes,
}: EmployeesVehicleGridProps) => {
    const { t } = useTranslation();

    const timeRange = useAppSelector(
        (state) => state.vehiclesWorkTime.filters.timeRange
    );
    const selectedVehicles = useAppSelector(
        (state) => state.vehiclesWorkTime.filters.selectedVehicles
    );
    const selectedCostCenters = useAppSelector(
        (state) => state.vehiclesWorkTime.filters.selectedCostCenters
    );
    const selectedEmployees = useAppSelector(
        (state) => state.vehiclesWorkTime.filters.selectedEmployees
    );

    const filterByVehicles = (vehicleId: string) => {
        if (!selectedVehicles || selectedVehicles.length === 0) return true;
        if (!selectedVehicles.includes(vehicleId)) return false;
        return true;
    };

    const filterByCostCenters = (costCenterId: string) => {
        if (!selectedCostCenters || selectedCostCenters.length === 0)
            return true;
        if (!selectedCostCenters.includes(costCenterId)) return false;
        return true;
    };

    const filterByEmployees = (employeeId: string) => {
        if (!selectedEmployees || selectedEmployees.length === 0) return true;
        if (!selectedEmployees.includes(employeeId)) return false;
        return true;
    };

    const employeeData = useMemo(
        () =>
            workShifts.flatMap((s) =>
                s.workShiftTimeSlots
                    .filter(
                        (slot) =>
                            !!slot.vehicleId &&
                            filterByVehicles(slot?.vehicleId) &&
                            filterByCostCenters(slot?.costCenterId) &&
                            filterByEmployees(slot?.employeeId)
                    )
                    .map((slot) => ({
                        id: slot.employeeId,
                        workSlotItem: {
                            employeeId: slot.employeeId,
                            vehicleId: slot.vehicleId,
                            costCenterId: slot.costCenterId,
                            workTimeTypeId: slot.workTimeTypeId,
                            comment: slot.comment,
                            startTime: slot.startDate,
                            endTime: slot.endDate,
                            amount: new WorkShiftTimeSlotItem(
                                slot
                            ).getDurationMinutes(),
                            dateStr: s.effectiveDate,
                        } as WorkSlotItem,
                    }))
            ),
        [workShifts, selectedVehicles, selectedCostCenters, selectedEmployees]
    );

    useAsyncThunkAction(
        () => {
            if (!timeRange[0] || !timeRange[1]) {
                return;
            }
            return fetchCalendarDays({
                startDate: timeRange[0],
                endDate: timeRange[1],
            });
        },
        { onError: () => null },
        [timeRange[0], timeRange[1]]
    );

    // Group data by date
    const groupedData = _groupBy(
        employeeData,
        (w) => `${w.id}_${w.workSlotItem.dateStr}`
    );

    // Create grid rows, calculate totals for each group
    const gridRows: GridRowsProp<GridRow> = _map(groupedData, (value, key) => {
        const workSlot = value[0].workSlotItem;
        const date = workSlot.dateStr;
        return {
            id: key,
            hierarchy: [workSlot.employeeId, date],
            workSlotItems: value.map((v) => v.workSlotItem),
            vehicles: vehicles,
            costCenters: costCenters,
            workTimeTypes: workTimeTypes,
            date,
            totals: value.reduce((acc, curr) => {
                acc[curr.workSlotItem.vehicleId] =
                    (acc[curr.workSlotItem.vehicleId] ?? 0) +
                    curr.workSlotItem.amount;

                acc["v_total"] =
                    (acc["v_total"] ?? 0) + curr.workSlotItem.amount;

                return acc;
            }, {}),
        };
    }).sort((a, b) => Base.stringCompare(a.date, b.date));

    // Group vehicles by register number
    const groupedVehicles = vehicles?.reduce((acc, vehicle) => {
        if (!acc[vehicle.registerNumber]) {
            acc[vehicle.registerNumber] = [];
        }
        acc[vehicle.registerNumber].push(vehicle);
        return acc;
    }, {} as Record<string, typeof vehicles>);

    const gridDef: GridColDef<GridRow>[] = useMemo(
        () => [
            {
                field: "v_total",
                headerName: Translations.Total,
                description: Translations.Total,
                groupable: false,
                type: "number",
                minWidth: 100,
                flex: 1,
                valueGetter(params) {
                    return (params.row?.totals?.v_total ?? 0) / 60;
                },
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(2, "fi-Fi");
                },
            },
            ...Object.entries(groupedVehicles).map(([registerNumber, vehicles]) => ({
                // if multiple vehicleIds has same register number, use id of the last vehicle
                // otherwise GroupedDataGrid will hide the column because it can't find values from row.totals[vehicleId]
                field: vehicles?.length > 1
                    ? vehicles[vehicles.length - 1]?.id
                    : vehicles[0]?.id,
                headerName: registerNumber,
                description: `${registerNumber} - ${vehicles.map(v => v.brand).join(", ")}`,
                groupable: false,
                type: "number",
                minWidth: 100,
                flex: 1,
                valueGetter(params) {
                    return vehicles.reduce((sum, v) => sum + (params.row?.totals?.[v.id] ?? 0), 0) / 60;
                },
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(2, "fi-Fi");
                },
            })),
        ],
        [vehicles]
    );

    const pinnedColumns = useMemo(() => ["v_total"], []);

    const getEmployeeLabel = useCallback(
        (employeeId: string) =>
            employees?.find((e) => e.id === employeeId)?.fullName ?? "",
        [employees]
    );

    return (
        <div style={{ padding: "1rem" }} id="vehicles-container">
            <Typography variant="h3">{t("vehicle.vehicles")}</Typography>
            <GroupedDataGrid
                noRowsText={t("employee.noRows")}
                detailsComponent={EmployeeDetailsTable}
                columns={gridDef}
                rows={gridRows}
                groupingColDef={{
                    headerName: t("employee.employee"),
                    valueFormatter: (data) => {
                        // for exports
                        const value: string = data?.value ?? Translations.Total;
                        if (value.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)) {
                            // val is employeeId
                            return getEmployeeLabel(value);
                        } else {
                            // val is date
                            return value;
                        }
                    },
                }}
                getGroupLabel={getEmployeeLabel}
                persistStateKey="EmployeesVehicleGridState"
                pinnedColumns={pinnedColumns}
            />
        </div>
    );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Base } from "../framework/base";

export interface Filters {
    timeRange: [string, string];
    selectedVehicles: string[];
    selectedCostCenters: string[];
    selectedEmployees: string[];
    selectedEmployeeGroups: string[];
}

interface VehiclesWorkTimeState {
    filters: Filters;
}

const initialState: VehiclesWorkTimeState = {
    filters: {
        timeRange: [
            Base.dayjsToJsonDate(Base.dateStartOf(new Date(), "month")),
            Base.dayjsToJsonDate(Base.dateEndOf(new Date(), "month")),
        ],
        selectedVehicles: [],
        selectedCostCenters: [],
        selectedEmployees: [],
        selectedEmployeeGroups: [],
    },
};

export const vehiclesWorkTimeSlice = createSlice({
    name: "vehiclesWorkTime",
    initialState,
    reducers: {
        setTimeRange(state, action: PayloadAction<[string, string]>) {
            state.filters.timeRange = action.payload;
        },
        setSelectedVehicles(state, action: PayloadAction<string[]>) {
            state.filters.selectedVehicles = action.payload;
        },
        setSelectedCostCenters(state, action: PayloadAction<string[]>) {
            state.filters.selectedCostCenters = action.payload;
        },
        setSelectedEmployees(state, action: PayloadAction<string[]>) {
            state.filters.selectedEmployees = action.payload;
        },
        setSelectedEmployeeGroups(state, action: PayloadAction<string[]>) {
            state.filters.selectedEmployeeGroups = action.payload;
        },
        clearFilters: (state) => {
            state.filters = { ...initialState.filters };
        },
    },
});

export const {
    setTimeRange,
    setSelectedVehicles,
    setSelectedCostCenters,
    setSelectedEmployees,
    setSelectedEmployeeGroups,
    clearFilters,
} = vehiclesWorkTimeSlice.actions;
export const vehiclesWorkTimeReducer = vehiclesWorkTimeSlice.reducer;

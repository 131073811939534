import React, { useEffect, useMemo, useState } from "react";
import { Base } from "../../../framework/base";
import { useFetch } from "../../../hooks/useFetch";

import { ICostCenterItems } from "../../../models/costCenter/costCenterItems";
import { SalaryRowTypeItem } from "../../../models/salary/salaryRowTypeItem";
import { VehicleListItemDto } from "../../../models/transport/vehicle";

import { getCostCenterItems } from "../../../services/costCenterService";
import { getSalaryRowTypeItems } from "../../../services/salaryRowTypeService";
import { getVehicles } from "../../../services/vehicleService";

import { MainLayout } from "../../layout/mainLayout";
import { VehiclesWorkTimeGrid } from "./vehiclesWorkTimeGrid";
import { WorkTimeVehicleFilters } from "./workTimeVehicleFilters";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { IWorkHoursItem } from "../../../models/workTime/workHoursItem";
import * as workHoursService from "../../../services/workHoursService";
import { getAllWorkTimeTypes } from "../../../services/workTimeTypeService";
import { WorkTimeTypeListItem } from "../../../models/workTimeType/workTimeTypeListItem";
import { getEmployees } from "../../../services/employeeServiceNew";
import { EmployeeListItemDto } from "../../../models/employee/employee";
import { LoadingIndicator } from "../../framework/loadingIndicatorNew";
import { WorkTimeType } from "../../../models/workShitTimeSlot/workTimeType";
import { WorkShiftDto } from "../../../models/workShift/workShift";
import { getWorkShifts } from "../../../services/workShiftService";
import { handleApiError } from "../../../models/store/storeEffects";

export const VehiclesMain = () => {
    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState<VehicleListItemDto[]>();
    const [employees, setEmployees] = useState<EmployeeListItemDto[]>();
    const [salaryRowTypes, setSalaryRowTypes] = useState<SalaryRowTypeItem[]>();
    const [costCenters, setCostCenters] = useState<ICostCenterItems>();
    const [workTimeTypes, setWorkTimeTypes] = useState<WorkTimeTypeListItem[]>();
    const [workHours, setWorkHours] = useState<IWorkHoursItem[]>();
    const [workShifts, setWorkShifts] = useState<WorkShiftDto[]>();

    const dispatch = useAppDispatch();

    const timeRange = useAppSelector(state => state.vehiclesWorkTime.filters.timeRange);
    const selectedEmployeeGroups = useAppSelector(
        (state) => state.vehiclesWorkTime.filters.selectedEmployeeGroups
    );

    const allEmployeeIds = useMemo(
        () =>
            employees
                ?.filter(
                    (e) =>
                        selectedEmployeeGroups.length === 0 ||
                        selectedEmployeeGroups.includes(e.salaryGroupId)
                )
                .map((e) => e.id) ?? [],
        [employees, selectedEmployeeGroups]
    );

    const presentEmployeeIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workHours?.map((w) => w.employeeId).filter(Boolean) ?? []
            ),
        [workHours]
    );

    const presentEmployees = useMemo(
        () =>
            employees?.filter((v) => presentEmployeeIds?.includes(v.id)) ?? [],
        [employees, presentEmployeeIds]
    );

    const vehicleIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workHours?.map((w) => w.vehicleId).filter(Boolean) ?? []
            ),
        [workHours]
    );

    const presentVehicles = useMemo(
        () => vehicles?.filter((v) => vehicleIds?.includes(v.id)) ?? [],
        [vehicleIds, vehicles]
    );

    const costCenterIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workHours?.map((w) => w.costCenterId).filter(Boolean) ?? []
            ),
        [workHours]
    );

    const presentCostCenters = useMemo(
        () =>
            costCenters?.items.filter((v) => costCenterIds?.includes(v.id)) ??
            [],
        [costCenterIds, costCenters]
    );

    const workTimeTypeIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workShifts
                    ?.flatMap((w) =>
                        w.workShiftTimeSlots.map((s) => s.workTimeTypeId)
                    )
                    .filter(Boolean) ?? []
            ),
        [workShifts]
    );

    const presentWorkTimeTypes = useMemo(
        () => [
            new WorkTimeType({
                workTimeTypeId: "wtt_null",
                name: "Ei työvaihetta",
                type: 0,
            }),
            ...(workTimeTypes?.filter((v) =>
                workTimeTypeIds?.includes(v.workTimeTypeId)
            ) ?? []),
        ],
        [workTimeTypeIds, workTimeTypes]
    );

    const loadWorkHours = async (
        cb: (result: IWorkHoursItem[]) => void,
        signal?: AbortSignal
    ) => {
        await workHoursService
            .getVehicleWorkHours(
                allEmployeeIds,
                timeRange[0],
                timeRange[1],
                signal
            )
            .then(cb)
            .catch((err) => {
                if (!signal?.aborted) {
                    handleApiError(err, dispatch);
                    console.log("error", err);
                }
            });
    };

    const loadWorkShifts = async (signal?: AbortSignal) =>
        await getWorkShifts(
            allEmployeeIds,
            timeRange[0],
            timeRange[1],
            true,
            signal
        )
            .then((res) => setWorkShifts(res))
            .catch((err) => {
                if (!signal?.aborted) {
                    handleApiError(err, dispatch);
                    console.log("error", err);
                }
            });

    useEffect(() => {
        const abortController = new AbortController();
        const responses = [];
        setLoading(true);

        responses.push(
            void loadWorkHours((result) => {
                setWorkHours(result.filter((r) => !!r.salaryRowTypeId));
            }),
            abortController.signal
        );

        responses.push(loadWorkShifts(abortController.signal));

        Promise.all(responses).finally(() => setLoading(false));

        return () => {
            abortController.abort();
        };
    }, [timeRange, allEmployeeIds]);

    useFetch(
        (signal) => getVehicles(true, signal),
        {
            onSuccess: (res) => setVehicles(res.data),
        },
        []
    );

    useFetch(
        (signal) => getEmployees(null, signal),
        {
            onSuccess: (res) => setEmployees(res.data),
        },
        []
    );

    useFetch(
        () => getSalaryRowTypeItems(1000, 1, "", "name", true),
        {
            onSuccess: (res) => {
                res.items.sort((a, b) => Base.stringCompare(a.code, b.code));
                setSalaryRowTypes(res.items);
            },
        },
        []
    );

    useFetch(
        () => getCostCenterItems(1000, 1, "", "name", true, true),
        {
            onSuccess: (res) => setCostCenters(res),
        },
        []
    );

    useFetch(
        (signal) => getAllWorkTimeTypes(signal),
        {
            onSuccess: (res) => setWorkTimeTypes(res),
        },
        []
    );

    const dataInitialized = workHours && workShifts && salaryRowTypes && workTimeTypes;

    return (
        <MainLayout
            noContainer
            className="fluid"
            topComponent={
                <WorkTimeVehicleFilters
                    vehicles={presentVehicles}
                    costCenters={presentCostCenters}
                    employees={presentEmployees}
                />
            }
        >
            <div style={{ padding: "1rem" }}>
                <LoadingIndicator loading={loading} />
                {dataInitialized && (
                    <VehiclesWorkTimeGrid
                        employees={employees}
                        salaryRowTypes={salaryRowTypes}
                        vehicles={vehicles}
                        costCenters={costCenters}
                        workTimeTypes={presentWorkTimeTypes}
                        workHours={workHours}
                        workShifts={workShifts}
                    />
                )}
            </div>
        </MainLayout>
    );
};

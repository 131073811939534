import React from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatTime, formatWorkTime } from "../../salaries/salariesListGrid";
import { GridRow } from "./vehiclesWorkTimeGrid";
import { IWorkHoursItem } from "../../../models/workTime/workHoursItem";
import { useSelectCalendarDay } from "../../../hooks/useCalendar";

const LinkToEmployeeWorkTimeDetails = (employeeId: string, dateStr: string, content: string) => {
    const calendarDay = useSelectCalendarDay(dateStr);
    return (
        <Box>
            <Link
                title={calendarDay?.isHoliday ? calendarDay?.description : ""}
                onClick={(e) => e.stopPropagation()}
                style={{ color: calendarDay?.isHoliday ? "red" : "inherit" }}
                target="_blank" to={`/worktimepage/details/${employeeId}/${dateStr}`}
            >
                {content}
            </Link>
        </Box>
    );
};

interface VehicleDetailsProps {
    row: GridRow;
}

interface IWorkHoursItemUnique extends IWorkHoursItem {
    rowId?: number;
}

export const VehicleDetailsTable = (props: VehicleDetailsProps) => {
    const {
        workHourItems,
        salaryRowTypes,
        employees,
        costCenters,
        workTimeTypes,
    } = props.row;

    const workHours = workHourItems.map((wh, i) => { return { ...wh, "rowId": i }; });

    return (
        <Box sx={{ p: 3, width: "fit-content", position: "sticky", left: 0 }}>
            <TableContainer >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t("vehicle.startTime")}
                            </TableCell>
                            <TableCell>
                                {t("vehicle.endTime")}
                            </TableCell>
                            <TableCell>
                                {t("vehicle.employee")}
                            </TableCell>
                            <TableCell>
                                {t("vehicle.amount")}
                            </TableCell>
                            <TableCell colSpan={2}>
                                {t("vehicle.salaryRowType")}
                            </TableCell>
                            <TableCell>
                                {t("workTime.costCenter")}
                            </TableCell>
                            <TableCell>
                                {t("workTime.workTimeType")}
                            </TableCell>
                            <TableCell>
                                {t("vehicle.comment")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { workHours && workHours.map((wh: IWorkHoursItemUnique) => {
                            const employee = employees.find(e => e.id === wh?.employeeId);
                            const employeeName = employee?.fullName || "-";

                            const srt = salaryRowTypes.find(srt => srt.id === wh?.salaryRowTypeId);
                            const costCenter = costCenters?.items.find(c => c?.id === wh?.costCenterId);
                            const workTimeType = workTimeTypes.find(wt => wt?.workTimeTypeId === wh?.workTimeTypeId);

                            const startTime = formatTime(
                                wh.startTime,
                                wh.dateStr
                            );
                            const endTime = formatTime(wh.endTime, wh.dateStr);

                            return (
                                <TableRow key={wh.rowId}>
                                    <TableCell>
                                        {LinkToEmployeeWorkTimeDetails(
                                            employee?.id,
                                            wh?.dateStr,
                                            startTime
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {LinkToEmployeeWorkTimeDetails(
                                            employee?.id,
                                            wh?.dateStr,
                                            endTime
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {LinkToEmployeeWorkTimeDetails(
                                            employee?.id,
                                            wh?.dateStr,
                                            employeeName
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {formatWorkTime(
                                            wh?.amount,
                                            srt?.measureUnit,
                                            2
                                        )}
                                    </TableCell>
                                    <TableCell>{srt?.code ?? "-"}</TableCell>
                                    <TableCell>
                                        {srt?.name ?? ""} ({srt?.measureUnit})
                                    </TableCell>
                                    <TableCell>
                                        {costCenter?.name ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                        {workTimeType?.name ?? "-"}
                                    </TableCell>
                                    <TableCell>{wh?.comment || "-"}</TableCell>
                                </TableRow>
                            );}
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

import React from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { formatTime, formatWorkTime } from "../../salaries/salariesListGrid";
import { GridRow, WorkSlotItem } from "./employeesVehicleGrid";
import { useSelectCalendarDay } from "../../../hooks/useCalendar";
import { Translations } from "../../../models/translations";
import { StrMeasureUnit } from "../../../models/common/enums";
import { useTranslation } from "react-i18next";

const LinkToEmployeeWorkTimeDetails = (
    employeeId: string,
    dateStr: string,
    content: string
) => {
    const calendarDay = useSelectCalendarDay(dateStr);
    return (
        <Box>
            <Link
                title={calendarDay?.isHoliday ? calendarDay?.description : ""}
                onClick={(e) => e.stopPropagation()}
                style={{ color: calendarDay?.isHoliday ? "red" : "inherit" }}
                target="_blank"
                to={`/worktimepage/details/${employeeId}/${dateStr}`}
            >
                {content}
            </Link>
        </Box>
    );
};

interface VehicleDetailsProps {
    row: GridRow;
}

interface IWorkSlotItemUnique extends WorkSlotItem {
    rowId?: number;
}

export const EmployeeDetailsTable = (props: VehicleDetailsProps) => {
    const { t } = useTranslation();

    const { vehicles, workSlotItems, costCenters, workTimeTypes } = props.row;

    const workShifts = workSlotItems.map((wh, i) => {
        return { ...wh, rowId: i };
    });

    return (
        <Box sx={{ p: 3, width: "fit-content", position: "sticky", left: 0 }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("vehicle.startTime")}</TableCell>
                            <TableCell>{t("vehicle.endTime")}</TableCell>
                            <TableCell>{t("vehicle.amount")}</TableCell>
                            <TableCell>{t("workTime.costCenter")}</TableCell>
                            <TableCell>{t("workTime.workTimeType")}</TableCell>
                            <TableCell>{Translations.Vehicle}</TableCell>
                            <TableCell>{t("vehicle.comment")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workShifts &&
                            workShifts.map((wh: IWorkSlotItemUnique) => {
                                const costCenter = costCenters?.items.find(
                                    (c) => c?.id === wh?.costCenterId
                                );
                                const workTimeType = workTimeTypes.find(
                                    (wt) =>
                                        wt?.workTimeTypeId ===
                                        wh?.workTimeTypeId
                                );
                                const vehicle = vehicles?.find(
                                    (wt) => wt?.id === wh?.vehicleId
                                );

                                const startTime = formatTime(
                                    wh.startTime,
                                    wh.dateStr
                                );
                                const endTime = formatTime(
                                    wh.endTime,
                                    wh.dateStr
                                );

                                return (
                                    <TableRow key={wh.rowId}>
                                        <TableCell>
                                            {LinkToEmployeeWorkTimeDetails(
                                                wh?.employeeId,
                                                wh?.dateStr,
                                                startTime
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {LinkToEmployeeWorkTimeDetails(
                                                wh?.employeeId,
                                                wh?.dateStr,
                                                endTime
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {formatWorkTime(
                                                wh?.amount,
                                                StrMeasureUnit.HourFi,
                                                2
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {costCenter?.name ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {workTimeType?.name ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {vehicle?.registerNumber ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {wh?.comment || "-"}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
